import store from "@/state/store";
import { PERMISSIONS } from '@/utils/roles';


// Define the routes for the application
export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },

  {
    path: "/pages/starter",
    name: "starter",
    meta: { title: "Starter", authRequired: true },
    component: () => import("../views/pages/starter"),
  },
  {
    path: "/pages/maintenance",
    name: "maintenance",
    meta: { title: "Maintanance", authRequired: true },
    component: () => import("../views/pages/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Comming Soon", authRequired: true },
    component: () => import("../views/pages/coming-soon"),
  },
  {
    path: '/auth/signin-basic',
    name: 'signin-basic',
    meta: {
      title: "Signin Basic", authRequired: true,
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/auth/signin-cover',
    name: 'signin-cover',
    meta: {
      title: "Signin Cover", authRequired: true,
    },
    component: () => import('../views/auth/signin/cover')
  },
  {
    path: '/auth/signup-basic',
    name: 'signup-basic',
    meta: {
      title: "Signup Basic", authRequired: true,
    },
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/auth/signup-cover',
    name: 'signup-cover',
    meta: {
      title: "Signup Cover", authRequired: true,
    },
    component: () => import('../views/auth/signup/cover')
  },
  {
    path: '/auth/reset-pwd-basic',
    name: 'reset-pwd-basic',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/basic')
  },
  {
    path: '/auth/reset-pwd-cover',
    name: 'reset-pwd-cover',
    meta: {
      title: "Reset Password", authRequired: true,
    },
    component: () => import('../views/auth/reset/cover')
  },
  {
    path: '/auth/create-pwd-basic',
    name: 'create-pwd-basic',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/basic')
  },
  {
    path: '/auth/create-pwd-cover',
    name: 'create-pwd-cover',
    meta: {
      title: "create Password", authRequired: true,
    },
    component: () => import('../views/auth/create/cover')
  },
  {
    path: '/auth/lockscreen-basic',
    name: 'lock-screen-basic',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/lockscreen-cover',
    name: 'lock-screen-cover',
    meta: {
      title: "Lock Screen", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/cover')
  },
  {
    path: '/auth/twostep-basic',
    name: 'twostep-basic',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/basic')
  }, {
    path: '/auth/twostep-cover',
    name: 'twostep-cover',
    meta: {
      title: "Two Step Auth", authRequired: true,
    },
    component: () => import('../views/auth/twostep/cover')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: true,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: true,
    },
    component: () => import('../views/auth/logout/cover')
  },
  {
    path: '/auth/success-msg-basic',
    name: 'success-msg-basic',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/basic')
  },
  {
    path: '/auth/success-msg-cover',
    name: 'success-msg-cover',
    meta: {
      title: "Success Message", authRequired: true,
    },
    component: () => import('../views/auth/success-msg/cover')
  },
  {
    path: "/ecommerce/orders",
    name: "orders",
    meta: { title: "Orders", authRequired: true, permission: PERMISSIONS.VIEW_ORDERS },
    component: () => import("../views/apps/ecommerce/orders"),
  },
  {
    path: "/ecommerce/products",
    name: "products",
    meta: { title: "Products", authRequired: true },
    component: () => import("../views/apps/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-detail",
    name: "product-detail",
    meta: { title: "Product", authRequired: true },
    component: () => import("../views/apps/ecommerce/add-product"),
  },
  {
    path:"/ecommerce/products-category-position",
    name:"products_category_position",
    meta: { title: "Products Category Position", authRequired: true },
    component: () => import("../views/apps/ecommerce/products-category-position"),
  },
  {
    path: "/ecommerce/spedizioni/ciocca",
    name: "ciocca-shipping",
    meta: { title: "Shipping", authRequired: true },
    component: () => import("../views/apps/ecommerce/shipping"),
  },
  {
    path: "/ecommerce/spedizioni/sozzi",
    name: "shipping_sozzi",
    meta: { title: "Shipping Sozzi", authRequired: true },
    component: () => import("../views/apps/ecommerce/shipping"),
  },
  {
    path: "/ecommerce/spedizioni/drumohr",
    name: "shipping_drumohr",
    meta: { title: "Shipping Drumohr", authRequired: true },
    component: () => import("../views/apps/ecommerce/shipping_single_order"),
  },
  {
    path: "/ecommerce/orders/drumohr",
    name: "ordersDrumohr",
    meta: { title: "Orders Drumohr", authRequired: true },
    component: () => import("../views/apps/ecommerce/orders"),
  },
  {
    path: "/ecommerce/orders/sozzi",
    name: "ordersSozzi",
    meta: { title: "Orders Sozzi", authRequired: true },
    component: () => import("../views/apps/ecommerce/orders"),
  },
  {
    path: "/ecommerce/order-detail",
    name: "order-detail",
    meta: { title: "Order Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/order-details"),
  },
  {
    path: "/ecommerce/spedizioni/riepilogo-spedizioni",
    name: "shipment_summary",
    meta: { title: "Shipment Summary", authRequired: true },
    component: () => import("../views/apps/ecommerce/shipment_summary"),
  },
  {
    path: "/ecommerce/resi",
    name: "inserisci_resi",
    meta: { title: "Inserisci Resi", authRequired: true },
    component: () => import("../views/apps/ecommerce/resi"),
  },
  {
    path: "/ecommerce/lista-resi",
    name: "lista_resi",
    meta: { title: "Lista Resi", authRequired: true },
    component: () => import("../views/apps/ecommerce/list-resi"),
  },
  {
    path: "/ecommerce/resi/resi-details",
    name: "resi_details",
    meta: { title: "Resi Details", authRequired: true },
    component: () => import("../views/apps/ecommerce/resi-details"),
  },
  {
    path: "/ecommerce/ordini-mobile",
    name: "Ordini Mobile",
    meta: { title: "Ordini", authRequired: true },
    component: () => import("../views/apps/ecommerce/mobile/ordini-mobile"),
  },
  {
    path: "/ecommerce/dettaglio-ordini-mobile",
    name: "Dettaglio Ordini Mobile",
    meta: { title: "Dettaglio Ordini Mobile", authRequired: true },
    component: () => import("../views/apps/ecommerce/mobile/order-details-mobile"),
  },
  {
    path: "/ecommerce/actions-logs",
    name: "scheduler_action_log",
    meta: { title: "Scheduler Action Log", authRequired: true, permission: PERMISSIONS.VIEW_ADMIN },
    component: () => import("../views/apps/ecommerce/scheduler_action_logs"),
  },
  {
    path: "/ecommerce/order_note",
    name: "order_note",
    meta: { title: "Order Note", authRequired: true },
    component: () => import("../views/apps/ecommerce/mobile/lista-note"),
  },
  {
    path: "/ecommerce/spedizioni/spedizioni_resi",
    name: "spedizioni_resi",
    meta: { title: "Spedizioni Resi", authRequired: true },
    component: () => import("../views/apps/ecommerce/rispedizione-resi"),
  },
  {
    path: "/ecommerce/products/is_online",
    name: "products_online_status",
    meta: { title: "Prodcut Online Status", authRequired: true },
    component: () => import("../views/apps/ecommerce/product-online-status"),
  },
  {
    path: "/ecommerce/products/amazon_product_parent_list",
    name: "amazon_product_parent_list",
    meta: { title: "Amazon Product List", authRequired: true },
    component: () => import("../views/apps/ecommerce/amazon-product-parent-list"),
  },
  {
    path: "/ecommerce/products/amazon_product_parent_varient_list",
    name: "amazon_product_parent_varient_list",
    meta: { title: "Amazon Product List", authRequired: true },
    component: () => import("../views/apps/ecommerce/amazon-product-parent-varient-list"),
  },
  {
    path: "/ecommerce/products/upload_product",
    name: "upload_product",
    meta: { title: "Upload Products", authRequired: true },
    component: () => import("../views/apps/ecommerce/upload_product"),
  },
  {
    path: "/ecommerce/product-search",
    name: "product-search",
    meta: { title: "Product Search", authRequired: true },
    component: () => import("../views/apps/ecommerce/add-product-search.vue"),
  },
  {
    path: "/unauthorized",
    name: "unauthorized",
    meta: { title: "Unauthorized", authRequired: true },
    component: () => import("../views/auth/errors/unauthorized"),
  },
{
    path:"/b2b/clients-b2b-list",
    name:"clients_b2b_list",
    meta: { title: "Clients B2B List", authRequired: true, permission: PERMISSIONS.VIEW_B2B_CIOCCA },
    component: () => import("../views/apps/b2b/clients-b2b"),
  },
  {
    path: "/admin/launch-actions",
    name: "launch_actions",
    meta: { title: "Launch Actions", authRequired: true, permission: PERMISSIONS.VIEW_ADMIN },
    component: () => import("../views/apps/admin/launch_actions"),
  },
  {
    path: "/ecommerce/generate-zalando-docs",
    name: "generate-zalando-docs",
    meta: {
      title: "Generate Zalando Documents",
      authRequired: true,
    },
    component: () => import("../views/apps/ecommerce/zalando/generate-zalando-docs.vue"),
  }
  
];
