import { createWebHistory, createRouter } from "vue-router"
import axios from 'axios';
import routes from './routes';
import appConfig from "../../app.config";
//import store from '@/state/store';
import { hasPermission } from '@/utils/roles'; // Importa i ruoli e la funzione di controllo dei permessi

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});

router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  
  if (!authRequired) return next();

  try {
    axios.defaults.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('jwt');
    const response = await axios.get('https://dev.ciocca.it/esi1912cio/api_pass.php?url=http://94.138.172.178:65001/api/cwc/profile&psw=EV682DJ3%23i%23i');
    
    const userData = response.data.user;
    localStorage.setItem('userdata', JSON.stringify(userData));
    localStorage.setItem('userid', userData._id);
    localStorage.setItem('user', JSON.stringify(userData));

    // Assegna il ruolo direttamente dal backend
    const userRole = userData.role;
    localStorage.setItem('userRole', userRole);

    // Verifica dei permessi
    if (routeTo.meta.permission) {
      if (!hasPermission(userRole, routeTo.meta.permission)) {
        return next({ name: 'unauthorized' }); // Reindirizza a una pagina "non autorizzato"
      }
    }

    next();
  } catch (error) {
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } });
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }

  document.title = routeTo.meta.title + ' | ' + appConfig.title;
  next();
});

export default router;