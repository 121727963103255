export const ROLES = {
    ADMIN: 'admin',
    SPEDIZIONI_CIOCCA: 'spedizioni_ciocca',
    SPEDIZIONI_DRUMOHR: 'spedizioni_drumohr',
    SPEDIZIONI_SOZZI: 'spedizioni_sozzi',
    AGENTE_CIOCCA: 'agente_ciocca',
    AGENTE_SOZZI: 'agente_sozzi',
    AGENTE_DRUMOHR: 'agente_drumohr'
};
  
export const PERMISSIONS = {
    VIEW_ADMIN: 'VIEW_DASHBOARD',
    VIEW_ORDERS: 'VIEW_ORDERS',
    VIEW_PRODUCTS_WEB: 'VIEW_PRODUCTS_WEB',
    VIEW_B2B_CIOCCA: 'VIEW_B2B_CIOCCA',
    // Aggiungi altri permessi secondo necessità
};
  
const permissionMap = {
    [PERMISSIONS.VIEW_ADMIN]: [ROLES.ADMIN],
    [PERMISSIONS.VIEW_ORDERS]: [ROLES.ADMIN, ROLES.SPEDIZIONI_CIOCCA, ROLES.SPEDIZIONI_DRUMOHR, ROLES.SPEDIZIONI_SOZZI],
    [PERMISSIONS.VIEW_PRODUCTS_WEB]: [ROLES.ADMIN, ROLES.AGENTE_CIOCCA, ROLES.AGENTE_SOZZI, ROLES.AGENTE_DRUMOHR],
    [PERMISSIONS.VIEW_B2B_CIOCCA]: [ROLES.ADMIN, ROLES.AGENTE_CIOCCA]
    // Aggiungi altri permessi secondo necessità
};

export function hasPermission(userRole, permission) {
    return permissionMap[permission].includes(userRole);
}